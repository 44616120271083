import { Link } from "gatsby"
import React, { useState } from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { useWindowWidth } from "../utils/useWindowWidth"
interface Props {
  siteTitle: string
}

const Header = ({ siteTitle }: Props) => {
  const [showDrop, setShowDrop] = useState({
    programs: false,
    events: false,
  })
  const windowWidth = useWindowWidth()

  return (
    <header>
      <div>
        <Navbar expand="lg" fixed="top" className="boot-nav navbar-dark">
          <Navbar.Brand to="/" as={Link}>
            Flapmax{" "}
            {windowWidth! < 290 ? (
              <span>UA</span>
            ) : (
              <span>University Alliance</span>
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-right">
              <Nav.Link to="/join" as={Link}>
                Join
              </Nav.Link>

              <NavDropdown
                title="Programs"
                id="basic-nav-dropdown"
                show={showDrop.programs}
                onMouseLeave={() =>
                  setShowDrop({ ...showDrop, programs: false })
                }
                onMouseEnter={() =>
                  setShowDrop({ ...showDrop, programs: true })
                }
              >
                <NavDropdown.Item to="/student" as={Link}>
                  Fellowship
                </NavDropdown.Item>
                <NavDropdown.Item to="/collaboration" as={Link}>
                  Collaboration
                </NavDropdown.Item>
                <NavDropdown.Item to="/xhail" as={Link}>
                  Apprenticeship
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link to="/events" as={Link}>
                Events
              </Nav.Link>
              <Nav.Link href="https://community.flapmax.com" target="_blank">
                Community
              </Nav.Link>

              <Nav.Link href="https://blog.flapmax.com/" as="a" target="_blank">
                Blog
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  )
}

export default Header
